import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css';
import imgApp from "./assets/app.png"
import imgPlayStore from "./assets/playstore.png"
import imgAppStore from "./assets/appstore.png"
import imgLocation from "./assets/location.png"
import imgTime from "./assets/time.png"
import { IoLogoWhatsapp } from "react-icons/io"
import { IoLogoFacebook } from "react-icons/io5"
import { BiLogoInstagramAlt } from "react-icons/bi"
import { BsYoutube } from "react-icons/bs"

function App() {
  const date = new Date();
  const [t, i18n] = useTranslation('global');
  const [active, setActive] = useState('#home')
  const play= <img className="header-play" alt='Get it on Google Play' src={imgPlayStore}/>
  const app= <img className="header-play" alt='Get it on Google Play' src={imgAppStore}/>

  return (
    <>
      <nav className="navbar navbar-expand-lg pt-lg-5 py-4 font-a fixed-top bg-light">
        <div className="container">
          <div className="navbar-brand icon"></div>
          <div className="dropdown d-inline d-lg-none">
            <button className="nav-link dropdown-btn text-black" data-bs-toggle="dropdown" aria-expanded="false">
              <div className="lang"></div>
              {t("lang")}
            </button>
            <ul className="dropdown-menu">
              <li><button className="dropdown-item" onClick={() => i18n.changeLanguage("es")}>Español</button></li>
              <li><button className="dropdown-item" onClick={() => i18n.changeLanguage("en")}>English</button></li>
            </ul>
          </div>
          <div className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="menu"></span>
          </div>
          <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul className="nav">
              <li className="nav-item">
                <a href="#home" onClick={() => setActive('#home')} className={active === '#home' ? 'active' : ''}>{t("menu-home")}</a>
              </li>
              <li className="nav-item">
                <a href="https://sites.google.com/view/obii-tec/p%C3%A1gina-principal" target='_blank' rel='noreferrer' onClick={() => setActive('#terms')} className={active === '#terms' ? 'active' : ''}>{t("menu-terms")}</a>
              </li>
              <li className="nav-item">
                <a href="#driver" onClick={() => setActive('#driver')} className={active === '#driver' ? 'active' : ''}>{t("menu-driver")}</a>
              </li>
              <li className="nav-item">
                <a href='#downloads' >
                  <div className="logo-rider-sm"></div>
                </a>
              </li>
              <li className="nav-item dropdown d-none d-lg-block">
                <button className="nav-link dropdown-btn dropdown-toggle text-black" data-bs-toggle="dropdown" aria-expanded="false">
                  <div className="lang"></div>
                  {t("lang")}
                </button>
                <ul className="dropdown-menu">
                  <li><button className="dropdown-item" onClick={() => i18n.changeLanguage("es")}>Español</button></li>
                  <li><button className="dropdown-item" onClick={() => i18n.changeLanguage("en")}>English</button></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <header className="container" id="home">
        <div className="row">
          <div className="col-12 col-lg-7">
            <div className="header-logo"></div>
            <h1 className="mb-4 px-4 px-lg-0 text-white">{t("header-title")}</h1> 
            <p className="mb-4 px-4 px-lg-0 fst-normal text-white ">{t("header-detail")}</p>
          </div>
          <div className="header-img bg-move"></div>
        </div>
      </header>

      <section className="container my-5">
        <div className="row pt-5">
          <div className="col-12 my-2 my-lg-5 text-center">
            <h1>{t("title-one")}</h1>
          </div>
          <div className="col-12 col-lg-4">
            <div className="card card-orange">
              <div className="clock"></div>
              <h5 className="text-center fw-bold pb-3">{t("one-sub-one")}</h5>
              <div className="row">
                <div className="col-12 text-center">
                  <p className="w500 p2">
                    {t("one-sub-one-text-one")}
                  </p>
                </div>
                <div className="col-12 text-center">
                  <p className="w500 p2">
                    {t("one-sub-one-text-two")}
                  </p>
                </div>
                <div className="col-12 text-center">
                  <p className="w500 p2">
                    {t("one-sub-one-text-three")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4">
            <div className="card card-orange">
              <div className="lock"></div>
              <h5 className="text-center fw-bold pb-3">{t("one-sub-two")}</h5>
              <div className="row">            
                <div className="col-12 text-center">
                  <p className="w500 p2">
                    {t("one-sub-two-text-one")}
                  </p>
                </div>                
                <div className="col-12 text-center">
                  <p className="w500 p2">
                    {t("one-sub-two-text-two")}
                  </p>
                </div>
                <div className="col-12 text-center">
                  <p className="w500 p2">
                    {t("one-sub-two-text-three")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4">
            <div className="card card-orange">
              <div className="cardlogo"></div>
              <h5 className="text-center fw-bold pb-3">{t("one-sub-three")}</h5>
              <div className="row">
                <div className="col-12 text-center">
                  <p className="w500 p2">
                    {t("one-sub-three-text-one")}
                  </p>
                </div>
                <div className="col-12 text-center">
                  <p className="w500 p2">
                    {t("one-sub-three-text-two")}
                  </p>
                </div>
                <div className="col-12 text-center">
                  <p className="w500 p2">
                    {t("one-sub-three-text-three")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section  className="container my-lg-5 py-lg-5">
        <h1 className="text-center mb-lg-5">{t("title-two")}</h1>
        <div className="row">
          <div className="col-12">
            <Splide 
              aria-label=""
              options={ {
                height   : 'auto',
                wheel    : false,
              } }
              className=" mx-4 my-3 rounded d-none d-lg-block"
            >
              <SplideSlide>
                <div className="row mt-5">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-2 text-end">
                        <div className="number">1</div>
                      </div>
                      <div className="col-10 mb-5">
                        <h5 className="fw-bold ps-3 ps-lg-0">{t("two-sub-one")}</h5>
                        <p>{t("two-sub-one-text-one")}</p>
                      </div>

                      <div className="col-2 text-end text-off">
                        <div className="numberOff">2</div>
                      </div>
                      <div className="col-10 mb-5 text-off">
                        <h5 className="fw-bold">{t("two-sub-two")}</h5>
                        <p>{t("two-sub-two-text-one")}</p>
                      </div>

                      <div className="col-2 text-end text-off">
                        <div className="numberOff">3</div>
                      </div>
                      <div className="col-10 mb-5 text-off">
                        <h5 className="fw-bold">{t("two-sub-three")}</h5>
                        <p>{t("two-sub-two-text-one")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 text-center" >
                    <img src={imgApp} alt="" className="img-slide"/>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="row mt-5">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-2 text-end text-off">
                        <div className="numberOff">1</div>
                      </div>
                      <div className="col-10 mb-5 text-off">
                        <h5 className="fw-bold">{t("two-sub-one")}</h5>
                        <p>{t("two-sub-one-text-one")}</p>
                      </div>

                      <div className="col-2 text-end">
                        <div className="number">2</div>
                      </div>
                      <div className="col-10 mb-5">
                        <h5 className="fw-bold">{t("two-sub-two")}</h5>
                        <p>{t("two-sub-two-text-one")}</p>
                      </div>

                      <div className="col-2 text-end text-off">
                        <div className="numberOff">3</div>
                      </div>
                      <div className="col-10 mb-5 text-off">
                        <h5 className="fw-bold">{t("two-sub-three")}</h5>
                        <p>{t("two-sub-two-text-one")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 text-center" >
                    <img src={imgLocation} alt="" className="img-slide"/>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="row mt-5">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-2 text-end text-off">
                        <div className="numberOff">1</div>
                      </div>
                      <div className="col-10 mb-5 text-off">
                        <h5 className="fw-bold">{t("two-sub-one")}</h5>
                        <p>{t("two-sub-one-text-one")}</p>
                      </div>

                      <div className="col-2 text-end text-off">
                        <div className="numberOff">2</div>
                      </div>
                      <div className="col-10 mb-5 text-off">
                        <h5 className="fw-bold">{t("two-sub-two")}</h5>
                        <p>{t("two-sub-two-text-one")}</p>
                      </div>

                      <div className="col-2 text-end">
                        <div className="number">3</div>
                      </div>
                      <div className="col-10 mb-5">
                        <h5 className="fw-bold">{t("two-sub-three")}</h5>
                        <p>{t("two-sub-two-text-one")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 text-center" >
                    <img src={imgTime} alt="" className="img-slide"/>
                  </div>
                </div>
              </SplideSlide>
            </Splide>

            <div id="carouselExampleIndicators" className="carousel slide d-block d-lg-none">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row">
                    <div className="col-2 text-end">
                      <div className="number">1</div>
                    </div>
                    <div className="col-9 mb-5">
                      <h5 className="fw-bold ps-3 ps-lg-0">{t("two-sub-one")}</h5>
                      <p>{t("two-sub-one-text-one")}</p>
                    </div>
                  </div>                  
                  <img src={imgApp} className="d-block" alt="..."/>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <div className="col-2 text-end">
                      <div className="number">2</div>
                    </div>
                    <div className="col-9 mb-5">
                      <h5 className="fw-bold ps-3 ps-lg-0">{t("two-sub-two")}</h5>
                      <p>{t("two-sub-two-text-one")}</p>
                    </div>
                  </div> 
                  <img src={imgLocation} className="d-block" alt="..."/>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <div className="col-2 text-end">
                      <div className="number">3</div>
                    </div>
                    <div className="col-9 mb-5">
                      <h5 className="fw-bold ps-3 ps-lg-0">{t("two-sub-three")}</h5>
                      <p>{t("two-sub-three-text-one")}</p>
                    </div>
                  </div> 
                  <img src={imgTime} className="d-block" alt="..."/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className="col-12 text-center mt-4">
            <h5>{t("contact-msm")}</h5>
            <a href='https://wa.me/573144786898' target='_blank' rel="noreferrer">
              <IoLogoWhatsapp className="icon-orange"/>
            </a>
            <a href='https://www.facebook.com/share/97zTSxY5W9FSonF8/?mibextid=qi2Omg' target='_blank' rel="noreferrer">
              <IoLogoFacebook className="icon-orange"/>
            </a>
            <a href='https://www.instagram.com/obii_col?igsh=YXhjN2NrZWV6MGJu' target='_blank' rel="noreferrer">
              <BiLogoInstagramAlt className="icon-orange"/>
            </a>
            <a href='https://youtube.com/@obiitechnology9926?si=7sbbx4sC32asK1_q' target='_blank' rel="noreferrer">
              <BsYoutube  className="icon-orange"/>
            </a>
          </div>
        </div>
      </section>

      <section className="my-5 bg-driver" id="driver">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4 text-white card-blur">
              <div className="logo-light"></div>
              <h1 className="text-white">{t("title-four")}</h1>
              <p>{t("four-sub-one")}</p>             
              <a href='#downloads'>
                <div className="logo-driver"></div>                
              </a>
            </div>

          </div>
        </div>
      </section>

      <section id="downloads" className="container px-5 my-lg-5">
        <div className="row pt-5">
          <div className="col-12  text-center px-5 ">
            <h1>{t("downloads")}</h1>
          </div>
          <div className="col-6 p-5">
            <a href='https://play.google.com/store/apps/details?id=com.obiitaxi.conductor' target='_blank' rel='noreferrer'>
                <div className="logo-driver">
                  <span className="subtextAux">
                    {t("driver")}
                  </span>
                  <div>{play}</div>
                </div>                
              </a>
          </div>
          <div className="col-6 p-5">
            <a className="opacity-25">
                <div className="logo-driver">
                  <span className="subtextAux">
                    {t("driver")}
                  </span>
                  <div>{app}</div>
                </div>                
              </a>
          </div>
          <div className="col-6 p-5">
            <a href='https://play.google.com/store/apps/details?id=com.obiitaxi.usuario' target='_blank' rel='noreferrer'>
                <div className="logo-rider">
                  <span className="subtextAux">
                    {t("rider")}
                  </span>
                  <div>{play}</div>
                </div>                
              </a>
          </div>
          <div className="col-6 p-5">
            <a className="opacity-25" >
                <div className="logo-rider">
                  <span className="subtextAux">
                    {t("rider")}
                  </span>
                  <div>{app}</div>
                </div>                
              </a>
          </div>
         
        </div>
      </section>

      <section  className="container my-lg-5">
        <div className="row pt-5">
          <div className="col-12  text-center px-5">
            <h1>{t("title-three")}</h1>
          </div>
          <div className="col-12 px-lg-5">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    {t("three-sub-one")}
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                  <div className="accordion-body p2">
                    {t("three-sub-one-text-one")}
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    {t("three-sub-two")}
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body p2">
                    {t("three-sub-two-text-one")}                  
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    {t("three-sub-three")}
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body p2">
                    {t("three-sub-three-text-one")}                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*
      <section className="my-5">
        <div className="container">
          <div className="row">
            <div className="col-12  text-center px-5">
              <h1>{t("title-five")}</h1>
            </div>
            <div className="col-12 col-lg-6 p-3">
              <iframe className="video" src="https://www.youtube.com/embed/N0D9D2KElr0" title="📲Como utilizar la aplicación de Obii conductor🏁" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className="col-12 col-lg-6 p-3">
              <iframe className="video" src="https://www.youtube.com/embed/UCs5M73Pz9Q" title="📲Como utilizar la aplicación de Obii usuarios 🤳" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </section>
      */}


      <a className="wtp-aux" href='https://wa.me/573144786898' target='_blank' rel="noreferrer">
        {t("support")}
        <IoLogoWhatsapp className="wtp-icon"/>
      </a>

      <footer>
          <div className="container mt-5">
            <div className="row text-white">
              <div className="col-12 col-lg-4 text-center">
                <div className="logo-footer"></div>
                <p><a href='https://sites.google.com/view/obii-tec/p%C3%A1gina-principal' target='_blank' rel="noreferrer" className="small text-white text-decoration-none">{t("footer-terms")}</a></p>
                <p><a href='https://sites.google.com/view/obii/p%C3%A1gina-principal' target='_blank' rel="noreferrer" className="small text-white text-decoration-none">{t("footer-privacy")}</a></p>                
              </div>
              <div className="col-12 col-lg-4 text-center my-3 my-lg-0">
                <a href='https://wa.me/573144786898' target='_blank' rel="noreferrer">
                  <IoLogoWhatsapp className="icon-white"/>
                </a>
                <a href='https://www.facebook.com/share/97zTSxY5W9FSonF8/?mibextid=qi2Omg' target='_blank' rel="noreferrer">
                  <IoLogoFacebook className="icon-white"/>
                </a>
                <a href='https://www.instagram.com/obii_col?igsh=YXhjN2NrZWV6MGJu' target='_blank' rel="noreferrer">
                  <BiLogoInstagramAlt className="icon-white"/>
                </a>
                <a href='https://youtube.com/@obiitechnology9926?si=7sbbx4sC32asK1_q' target='_blank' rel="noreferrer">
                  <BsYoutube className="icon-white"/>
                </a>
              </div>
              <div className="col-12 col-lg-4 text-center my-3 my-lg-0">
                <div className='d-flex gap-5 justify-content-center'>
                <a href='#downloads'>
                  <div className="logo-rider-sm"><span className="subtext-white">{t("rider")}</span></div>
                </a>
                <a href='#downloads'>
                  <div className="logo-driver-sm"><span className="subtext-white">{t("driver")}</span></div>
                </a>
                </div>
             
              </div>

              <hr/>
              <div className="col-12 p-3 p-lg-0">
                <p className="small text-light">{t("footer-detail")}</p>                
                <p className="small text-light text-center">{t("footer-copyright")} {date.getFullYear()}</p>                
              </div>
            </div>
          </div>

      </footer>
    </>
  );
}

export default App;
