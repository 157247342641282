import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {I18nextProvider}  from 'react-i18next';
import i18next from 'i18next';
import es from './locale/es/global.json';
import en from './locale/en/global.json';

i18next.init({
  interpolation: { scapeValue: false},
  lng: "es",
  resources: {
    es: {
      global: es
    },
    en: {
      global: en
    },
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);